import { useInView } from 'react-intersection-observer'
import Slider from 'react-slick'
import { useRef, useEffect } from 'react'
import { viewItems } from '../trace'

import CartRecommendItem from '../CartItem/CartRecommendItem'
import { Text } from '@components/ui'
import { useDevice } from 'react-use-device'
import s from '../CartSidebarView/CartSidebarView.module.css'

const MobileRecommends = ({
  personalizeProducts,
  recommendsProducts,
  cartRecommends,
  shoppingInfo,
  getBundleSize,
  setRecommendsAddedSkuAdded,
  data,
  shopCommon,
}) => {
  const getInViewItems = () => {
    let items = []
    document
      ?.querySelectorAll('#PersonalizeRecommendsProducts .slick-active')
      ?.forEach((item) => {
        const index = parseInt(item.getAttribute('data-index') || '1')
        const product = personalizeProducts?.[index]
        items.push({
          item_id: product?.variant?.sku, //读取被推荐产品的SKU
          item_name: product?.name,
          item_variant: '',
          price: product?.variant?.price, //读取被推荐产品展示的折扣价
          index: index, //读取被推荐的位置，如1,2,3
        })
      })
    return items
  }
  const { isMOBILE: mobile } = useDevice()

  const skus = data?.lineItems?.reduce(
    (prev, cur) => prev + cur?.variant?.sku + ',',
    ''
  )

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: '18px',
    infinite: false,
    afterChange: () => {
      viewItems({ items: getInViewItems(), skus })
    },
  }

  const { ref, inView } = useInView({
    threshold: 0,
  })

  useEffect(() => {
    if (inView && mobile) {
      const items = getInViewItems()
      if (items.length) {
        viewItems({
          items: items,
          skus,
        })
      }
    }
  }, [inView, mobile, personalizeProducts])

  return (
    <div
      ref={ref}
      className="only-in-mobile"
      id="PersonalizeRecommendsProductsMobile"
    >
      <style jsx global>
        {`
          .${s.cartItems} .slick-slide {
            margin-left: 2px;
            margin-right: 2px;
          }
          .${s.cartItems} .slick-track {
            display: flex;
            align-items: stretch;
          }
          .${s.cartItems} .slick-slide {
            position: relative;
            height: auto;
          }
          .${s.cartItems} .slick-slide > div,
          .${s.cartItems} .slick-slide > div > div {
            height: 100%;
          }
          .${s.cartItems} .slick-slide > div > div {
            display: flex !important;
            flex-direction: column;
            justify-content: space-between;
          }
        `}
      </style>
      {/* 算法推荐商品 */}
      {personalizeProducts?.length > 0 && (
        <div className={s.recommendsContainer}>
          <Text
            className={s.recommendsTitle}
            html={shoppingInfo?.recommendsTitle}
            variant="paragraph"
          />
          <Slider {...settings}>
            {personalizeProducts.map((item, index) => {
              return (
                <CartRecommendItem
                  modal="recommends"
                  form="CartSidebarView"
                  key={`${item.id}${index}`}
                  item={item}
                  discountPrice={item?.__discountPrice || ''}
                  position={index + 1}
                  mainProduct={item?.mainProduct}
                  currencyCode={data.currency.code}
                  shopCommon={shopCommon}
                  allSku={data?.lineItems?.map((item) => item?.variant?.sku)}
                  getBundleSize={getBundleSize}
                  onRecommendsProductAdd={setRecommendsAddedSkuAdded}
                />
              )
            })}
          </Slider>
        </div>
      )}
      {/* 运营配置的推荐商品 */}
      {cartRecommends?.show && (
        <div id="RecommendsProducts">
          {recommendsProducts?.length > 0 && (
            <div className={s.recommendsContainer}>
              <Text
                className={s.recommendsTitle}
                html={shoppingInfo?.recommendsTitle}
                variant="paragraph"
              />
              <Slider {...settings}>
                {recommendsProducts.map((item, index) => {
                  return (
                    <CartRecommendItem
                      modal="recommends"
                      form="CartSidebarView"
                      key={`recommendsProducts${item.id}${index}`}
                      item={item}
                      discountPrice={item.__discountPrice || ''}
                      position={index + 1}
                      mainProduct={item.mainProduct}
                      currencyCode={data.currency.code}
                      shopCommon={shopCommon}
                      allSku={data?.lineItems?.map(
                        (item) => item?.variant?.sku
                      )}
                      getBundleSize={getBundleSize}
                      onRecommendsProductAdd={setRecommendsAddedSkuAdded}
                    />
                  )
                })}
              </Slider>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default MobileRecommends
