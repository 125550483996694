import { useEffect, useState, useMemo } from 'react'
import cn from 'classnames'
import { useRouter } from 'next/router'
import { PlusIcon } from '@heroicons/react/24/outline'
import { useUI } from '@components/ui/context'
import { Picture, Text } from '@components/ui'
import { useCommerce } from '@commerce/index'
import { BRAND } from '@shopify/const'
import usePrice from '@shopify/product/use-price'
import { useAddItem } from 'lib/shopify/api/cart'
import { ProductPrice } from '@components/product'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { atobID, getThemeUrl } from '@lib/utils/tools'
import s from './CartItem.module.css'
import BannerLink from '@components/common/BannerLink'

const placeholderImg = '/product-img-placeholder.svg'

const CartRecommendItem = ({
  item,
  bundleItem,
  currencyCode,
  shopCommon = {},
  mainProduct = '',
  discountPrice,
  modal = 'default',
  position,
  allSku,
  getBundleSize,
  onRecommendsProductAdd,
  quantityBreakDescription,
  removeItemCbk,
  ...rest
}) => {
  const bundle_name = 'recommends_discount'
  const { locale } = useRouter()
  const { closeSidebarIfPresent } = useUI()
  //根据sku限购需求，最大最小值从shop里配置的sku里获取
  const { shop } = useCommerce()
  const orderLimits = shop?.orderLimits
  const orderLimit = orderLimits?.limits?.[item?.variant?.sku]
  const limitMin = orderLimit?.min !== undefined ? orderLimit?.min : 1

  const [quantity, setQuantity] = useState(
    item.quantity >= limitMin ? item.quantity : limitMin
  )
  const addItem = useAddItem()

  const [amount, baseAmount] = useMemo(() => {
    const totalDiscount =
      item?.discountAllocations?.reduce(
        (pre, cur) => pre + Number(cur?.allocatedAmount?.amount || 0),
        0
      ) || 0
    return [
      Number(item.variant.price) * item.quantity - totalDiscount,
      item.variant.price * item.quantity,
    ]
  }, [item?.discountAllocations, item.quantity, item.variant.price])
  const { price, basePrice, free } = usePrice({
    amount: discountPrice && discountPrice > 0 ? Number(discountPrice) : amount,
    baseAmount: baseAmount,
    currencyCode,
  })

  const { metafields = {} } = item?.variant

  const addToCart = async (customAttributes) => {
    console.log('recommend', quantity)
    try {
      await addItem({
        productId: String(item?.id),
        variantId: String(item?.variant?.id || product?.variants[0]?.id),
        quantity: quantity || 1,
        customAttributes: customAttributes || [],
      })
    } catch (err) {
      console.warn('addToCart err', err)
    }
  }

  const options = useMemo(() => {
    return item?.variant?.options?.map((opt) => ({
      name: opt.displayName || opt.name,
      value: opt?.values?.length > 0 ? opt.values[0].label : opt.value,
    }))
  }, [item.options, item?.variant?.options, modal])

  const getCustomAttributes = (bundle_name) => {
    if (discountPrice && discountPrice > 0) {
      const id = atobID(item?.variant?.id || product?.variants[0]?.id).slice(-3)
      const bundleSize = getBundleSize(bundle_name) + 1
      const sku = mainProduct?.split(',')?.pop()
      const key = String(
        42 * Number(id) * parseInt(discountPrice * 100) * bundleSize
      )
      return [
        {
          key: '_mult_bundle_name',
          value: bundle_name,
        },
        {
          key: '_mult_bundle_price',
          value: parseInt(discountPrice * 100).toString(),
        },
        {
          key: '_mult_bundle_size',
          value: String(bundleSize),
        },
        {
          key: '_mult_bundle_main_sku',
          value: sku,
        },
        {
          key: '_mult_bundle_up',
          value: key.slice(0, 3),
        },
      ]
    }
    return false
  }

  useEffect(() => {
    // Reset the quantity state if the item quantity changes
    if (item.quantity !== Number(quantity)) {
      setQuantity(item.quantity)
    }
    // TODO: currently not including quantity in deps is intended, but we should
    // do this differently as it could break easily
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.quantity])

  const handleNameClick = () => {
    closeSidebarIfPresent()

    pageGTMEvent({
      event: 'uaEvent',
      eventCategory: 'Cart Pop Up_Bundle_title',
      eventAction: allSku?.join(','),
      eventLabel: item?.variant?.sku,
    })
    pageGTMEvent({ event_parameters: null })
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'select_item',
      event_parameters: {
        page_group: 'Cart Pop Up_' + allSku?.join(','), //取最后一个加入购物车的产品SKU
        action: 'title',
        item_list_name: 'Cart Pop Up_Bundle',
        items: [
          {
            item_id: item?.variant?.sku, //读取点击的产品SKU
            item_name: item?.name, //产品名
            item_variant: item?.variant?.name,
            price: price, //读取price,无价格则空
            index: position, //读取被推荐的位置，如1,2,3,4,5
          },
        ],
      },
    })
  }

  const handleImgClick = () => {
    closeSidebarIfPresent()

    pageGTMEvent({
      event: 'uaEvent',
      eventCategory: 'Cart Pop Up_Bundle_image',
      eventAction: allSku?.join(','),
      eventLabel: item?.variant?.sku,
    })
    pageGTMEvent({ event_parameters: null })
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'select_item',
      event_parameters: {
        page_group: 'Cart Pop Up_' + allSku?.join(','), //取最后一个加入购物车的产品SKU
        action: 'img',
        item_list_name: 'Cart Pop Up_Bundle',
        items: [
          {
            item_id: item?.variant?.sku, //读取点击的产品SKU
            item_name: item?.name, //产品名
            item_variant: item?.variant?.name,
            price: price, //读取price,无价格则空
            index: position, //读取被推荐的位置，如1,2,3,4,5
          },
        ],
      },
    })
  }

  const handleRecommendsProductAdd = () => {
    if (discountPrice && discountPrice > 0) {
      const customAttributes = getCustomAttributes(bundle_name)
      addToCart(customAttributes)
      // console.log('item:', item)
      onRecommendsProductAdd(item.variant?.sku)
    } else {
      addToCart()
      onRecommendsProductAdd(item.variant?.sku)
    }

    pageGTMEvent({
      event: 'uaEvent',
      eventCategory: 'Cart Pop Up_Bundle',
      eventAction: allSku?.join(','),
      eventLabel: item?.variant?.sku,
    })
    pageGTMEvent({
      event: 'eeEvent',
      eventCategory: 'EnhancedEcommerce',
      eventAction: 'Added Product',
      eventLabel: item?.variant?.sku,
      ecommerce: {
        currencyCode: currencyCode,
        add: {
          products: [
            {
              id: atobID(item?.id),
              name: item?.name,
              price: price,
              quantity: item?.quantity || 1,
              variant: item?.variant?.name,
              brand: BRAND,
              category: '',
            },
          ],
        },
      },
    })

    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'add_to_cart',
      event_parameters: {
        page_group: 'Cart Pop Up_' + allSku?.join(','),
        item_list_name: 'Cart Pop Up_add_Bundle',
        currency: currencyCode,
        value: price,
        items: [
          {
            item_id: item?.variant?.sku,
            item_name: item?.name,
            item_brand: BRAND,
            item_category: '',
            item_variant: item?.variant?.name,
            price: price,
            quantity: item?.quantity || 1,
          },
        ], //取点击推荐产品的信息
      },
    })
  }

  const link =
    (BRAND === 'eufy'
      ? getThemeUrl('/products' + item.path, locale)
      : `${locale === 'us' ? '' : '/' + locale}/products${item.path}`) +
    `?variant=${atobID(item?.variant?.id || product?.variants[0]?.id)}`

  return (
    <li className={cn(s.root, s.recommends)} {...rest}>
      <div className="flex h-full items-stretch space-x-2">
        {/* 产品图 */}
        <div className="relative self-center">
          <Picture
            className={cn(s.productImage, s.recommends)}
            source={item.variant.image?.url || placeholderImg}
            alt={item.variant.image?.altText || 'Product Image'}
          />
          <BannerLink to={link} onClick={handleImgClick} />
        </div>
        {/* 产品信息 */}
        <div className="flex h-auto flex-grow flex-col justify-evenly text-base">
          {/* 产品名 */}
          <a
            href={link}
            onClick={handleNameClick}
            className={s.productName}
            dangerouslySetInnerHTML={{
              __html: item.name || item.product_title,
            }}
          />
          {/* 产品选项 */}
          {options && options.length > 0 && (
            <div className="pb-1">
              {options?.map(
                (option, i) =>
                  !option?.value?.includes('Default Title') && (
                    <div
                      key={`${item.id}-${option.name}`}
                      className="text-sm text-[#999]"
                    >
                      {option.name}:
                      <span className="ml-1 overflow-hidden">
                        {option.value}
                      </span>
                      {i === options.length - 1 ? (
                        ''
                      ) : (
                        <span className="mr-3" />
                      )}
                    </div>
                  )
              )}
            </div>
          )}
          {metafields.cartInfo && (
            <div className={s.cartInfo}>
              <Text html={metafields.cartInfo}></Text>
            </div>
          )}
          {/* 产品推荐的价格和添加按钮 */}
          <div className="flex items-center justify-between justify-self-end">
            <ProductPrice
              className={s.productPrice}
              styles={s}
              free={free}
              price={price}
              basePrice={basePrice}
              availableForSale={item.variant?.availableForSale}
              settings={shopCommon}
            />
            <button
              className={cn(
                'ml-6 flex items-center rounded-3xl px-4 py-1 font-semibold text-white',
                s.recommendBtn,
                s[BRAND]
              )}
              onClick={handleRecommendsProductAdd}
            >
              <PlusIcon className="inline-block h-4 w-4" />
              <span className="">{shopCommon?.addbtn}</span>
            </button>
          </div>
        </div>
      </div>
    </li>
  )
}

export default CartRecommendItem
