import { useRouter } from 'next/router'
import { parse } from 'query-string'
import { useUI } from '@components/ui/context'
import getProductPersonalizeBySkus from '@lib/apis/get-product-personalize-by-skus'
import { getGaClientId, btoaID } from '@lib/utils/tools'
import { useCallback, useEffect, useState } from 'react'
import { getUniqueListBy, getRandomValueFromArray } from '@lib/utils/tools'
import useProduct from '@shopify/product/use-product'
import { useMemo } from 'react'
import { useCart } from 'lib/shopify/api/cart'

const useRecommendsProducts = ({
  recommendsAddedSku,
  show,
  cartRecommends,
  shoppingInfo,
}) => {
  const { gtmReady } = useUI()

  const { locale } = useRouter()
  const { data, isLoading, isEmpty } = useCart()

  const [personalizeProducts, setPersonalizeProducts] = useState() //自动推荐算法
  const [recommendsProducts, setRecommendsProducts] = useState([]) //运营配置

  const [skus, fetch_sku] = useMemo(() => {
    const cskus =
      data?.lineItems
        ?.filter((item) => {
          return item?.customAttributes?.every(
            (attribute) =>
              attribute.key !== '_giveaway_gradient_gifts' &&
              attribute.key !== '_giveaway_summerCampaignDiscount'
          )
        })
        ?.map((item) => item?.variant?.sku) || []

    if (cskus.length > 0) return [cskus, cskus?.[cskus?.length - 1]]
    return [[], '']
  }, [data?.lineItems])

  const fetchPersonalizeProducts = useCallback(async () => {
    if (skus.length === 0) return
    const clientId = await getGaClientId()
    // 通过链接参数做 AB
    let queries = parse(window.location.search)

    let plan
    if (shoppingInfo?.cartRecommends_ABTest) {
      plan = getRandomValueFromArray(shoppingInfo?.cartRecommends_ABTest || [])
    }

    const result = await getProductPersonalizeBySkus({
      locale,
      skus: [fetch_sku],
      clientId,
      limit: 5,
      plan: plan || '',
    })

    if (result?.data) {
      // 所有商品推荐集合
      const products = Object.keys(result?.data).reduce(
        (pre, cur) => [...pre, ...result?.data?.[cur]],
        []
      )
      const finalProducts = getUniqueListBy(products, 'sku') //商品去重
        .filter((p) => !skus.includes(p.sku)) //去除跟购物车内相同商品
        .sort((a, b) => b.ranking - a.ranking) //根据推荐图排名
        .slice(0, 5) //取购物车推荐排名前 5
        .map(normalizeProduct)

      setPersonalizeProducts(finalProducts)

      // AB 测试埋点
      if (plan) {
        // const url = addQueryParam({
        //   url: document.location.href,
        //   key: 'cartPlan',
        //   value: plan,
        //   replaceHistory: true,
        // })
        // const urlObj = new URL(url)
        // const searchParams = new URLSearchParams(urlObj.search)
        // if (searchParams.has('listingPlan') && searchParams.has('cartPlan')) {
        //   pageGTMEvent({
        //     event: 'pageview',
        //     page: url,
        //   })
        // }
      }
    }
  }, [locale, skus, fetch_sku, gtmReady])

  useEffect(() => {
    // 初始化获取
    if (!isLoading && !isEmpty && gtmReady) {
      fetchPersonalizeProducts()
    }
  }, [isLoading, isEmpty, fetch_sku, gtmReady])

  // 初始化推荐商品
  const recommends = useMemo(() => {
    const skus = data?.lineItems?.map((item) => item?.variant?.sku) || []
    const results = []
    cartRecommends?.items?.forEach(([a, b]) => {
      if (skus.some((s) => a.includes(s)) && !results.includes(b)) {
        results.push(b)
      } else if (skus.some((s) => b.includes(s)) && !results.includes(a)) {
        results.push(a)
      }
    })
    return results
      .map((v) => v.split(','))
      .filter((res) => res && skus.every((sku) => sku !== res[1]))
  }, [])
  // console.log('recommends:', recommends)

  // 获取推荐产品相关数据
  const { data: recommendsProductsResult } = useProduct({
    handles: cartRecommends?.show
      ? recommends.map(([handle, sku]) => handle).join(',')
      : '',
  })
  // console.log('recommendsProductsResult:', recommendsProductsResult)

  // 生成最终需要的数据
  useEffect(() => {
    // 筛掉没有找到数据的推荐商品
    const availableRecommends = recommends?.filter(([handle, sku]) =>
      recommendsProductsResult?.products?.find(
        (product) => product?.handle?.toLowerCase() === handle?.toLowerCase()
      )
    )
    const products = recommendsProductsResult?.products
      ?.map((item, index) => {
        const [handle, sku, discountPrice] = availableRecommends[index]
        const variant = item.variants.find((v) => v.sku === sku)
        let mainProduct
        cartRecommends?.items?.forEach(([a, b]) => {
          if (a.includes(sku)) {
            mainProduct = b
          } else if (b.includes(sku)) {
            mainProduct = a
          }
        })
        return {
          ...item,
          variant,
          __discountPrice: discountPrice,
          quantity: 1,
          mainProduct,
        }
      })
      ?.filter(({ variant }) => variant?.availableForSale)
    setRecommendsProducts(products)
  }, [recommendsProductsResult])

  useEffect(() => {
    // 推荐位点击添加到购物后立马去除
    setRecommendsProducts(
      recommendsProducts?.filter((p) => p.variant?.sku !== recommendsAddedSku)
    )
    setPersonalizeProducts(
      personalizeProducts?.filter((p) => p.variant?.sku !== recommendsAddedSku)
    )
  }, [recommendsAddedSku])
  // console.log('recommendsProducts:', recommendsProducts)

  return { personalizeProducts, recommendsProducts }
}

export default useRecommendsProducts

const normalizeProduct = (product) => ({
  quantity: 1,
  mainProduct: product.sku,
  name: product.product_title,
  path: `/${product?.handle}`,
  variant: {
    id: btoaID(product.variant_shopify_id),
    name: product.product_title,
    sku: product.sku,
    price: product.variant_price,
    availableForSale: true,
    image: {
      url: product.variant_image,
      altText: product.product_title,
    },
    options: product.selected_options,
  },
})
