import { useState, useMemo } from 'react'
import cn from 'classnames'
import { useRouter } from 'next/router'
import { Picture } from '@components/ui'
import usePrice, { formatPrice } from '@shopify/product/use-price'
import { useCart, useCartAttributesUpdate } from 'lib/shopify/api/cart'
import useProduct from '@shopify/product/use-product'

const ShoppingCartGifts = ({ className, lang }) => {
  const { data } = useCart()
  const { locale } = useRouter()
  const { data: result, isLoading } = useProduct({
    handles: lang?.breakpoints
      ?.map((item) => item?.giveawayProducts[0]?.handle)
      ?.join(','),
  })

  const dealsItem = useMemo(() => {
    if (!data?.lineItems) return []
    return data?.lineItems?.filter((line) => {
      const notGift = lang?.breakpoints?.every(
        (item) =>
          item?.giveawayProducts[0]?.handle?.toUpperCase() !==
          line?.handle?.toUpperCase()
      )
      return (
        notGift &&
        line?.product?.tags?.some((tag) => lang?.includeTags?.includes(tag))
      )
    })
  }, [data])

  const [subtotal, total] = useMemo(() => {
    return dealsItem?.reduce(
      (item, currentV) => {
        const [subtotal, total] = item
        return [
          subtotal + Number(currentV?.subtotalAmount),
          total + Number(currentV?.totalAmount),
        ]
      },
      [0, 0]
    )
  }, [dealsItem])

  function findSmallestIndexAboveThreshold(breakpoints, threshold) {
    const filtered = breakpoints.filter(
      (item) => Number(item.breakpoint) > threshold
    )
    const smallestAboveThreshold = filtered.reduce(
      (smallest, item) => {
        return Number(item.breakpoint) < Number(smallest.breakpoint)
          ? item
          : smallest
      },
      { breakpoint: Infinity }
    )
    const index = breakpoints.findIndex(
      (item) =>
        Number(item.breakpoint) === Number(smallestAboveThreshold.breakpoint)
    )
    return index
  }

  const [nextStep, nextPrice] = useMemo(() => {
    if (
      !data?.currency?.code ||
      data?.lineItems?.some((item) => {
        return (
          !item?.product?.tags?.some((tag) =>
            lang?.includeTags?.includes(tag)
          ) && !item?.product?.tags?.includes(lang?.mysteryTag)
        )
      })
    ) {
      return [0, 'end']
    }
    const next = findSmallestIndexAboveThreshold(
      lang?.breakpoints,
      lang?.useTotalAmount ? total : subtotal
    )

    if (next > -1) {
      const nextPrice = lang?.useTotalAmount
        ? (lang?.breakpoints[next]?.breakpoint - total).toFixed(2)
        : (lang?.breakpoints[next]?.breakpoint - subtotal).toFixed(2)
      const price = formatPrice({
        amount: Number(nextPrice),
        currencyCode: data?.currency?.code,
        locale,
      })
      return [next, price]
    }
    return [lang?.breakpoints?.length - 1, 'end']
  }, [subtotal, data, lang?.stepPrice])

  const nextGift = useMemo(() => {
    if (nextStep >= 0 && result?.products) {
      const handle = lang?.breakpoints[nextStep]?.giveawayProducts[0].handle
      return result?.products?.find(
        (p) => p?.handle?.toUpperCase() === handle?.toUpperCase()
      )
    }
    return false
  }, [nextStep, result])

  return (
    nextGift?.handle &&
    nextPrice !== 'end' && (
      <div className={cn(className, 'bg-[#4FA5DB]')}>
        <div className="flex items-center justify-between px-4 py-1">
          <p
            className="text-sm font-semibold text-white"
            dangerouslySetInnerHTML={{
              __html: lang?.cartDescription?.replace(
                '$price',
                `<b>${nextPrice}</b>`
              ),
            }}
          />
          <Picture source={nextGift?.images[0]?.url} className=" w-[50px]" />
        </div>
      </div>
    )
  )
}

export default ShoppingCartGifts
