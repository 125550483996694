import { Text } from '@components/ui'
import CartRecommendItem from '../CartItem/CartRecommendItem'
import { viewItems } from '../trace'

import { useDevice } from 'react-use-device'

import { useRef, useEffect } from 'react'
import s from '../CartSidebarView/CartSidebarView.module.css'
import { cn } from '@components/helper'

const DesktopRecommends = ({
  personalizeProducts,
  recommendsProducts,
  cartRecommends,
  shoppingInfo,
  getBundleSize,
  setRecommendsAddedSkuAdded,
  data,
  shopCommon,
  show,
}) => {
  const hasRecommends =
    personalizeProducts?.length ||
    (cartRecommends?.show && recommendsProducts?.length)

  const { isMOBILE: mobile } = useDevice()

  // PC 展开都能看到，全部一起触发 view 事件
  useEffect(() => {
    if (show && !mobile && personalizeProducts?.length) {
      viewItems({
        items: personalizeProducts?.map((product, index) => ({
          item_id: product?.variant?.sku, //读取被推荐产品的SKU
          item_name: product?.name,
          item_variant: '',
          price: product?.variant?.price, //读取被推荐产品展示的折扣价
          index: index, //读取被推荐的位置，如1,2,3
        })),
        skus: data?.lineItems?.reduce(
          (prev, cur) => prev + cur?.variant?.sku + ',',
          ''
        ),
      })
    }
  }, [show, mobile, personalizeProducts, data?.lineItems])

  return (
    <div
      id="PersonalizeRecommendsProducts"
      className={cn('only-in-pc', s.right, hasRecommends ? 'w-[375px]' : 'w-0')}
    >
      {/* 算法推荐商品 */}
      {personalizeProducts?.length > 0 && (
        <div className="px-5 pb-8 md:pb-4">
          <Text
            className={s.recommendsTitle}
            html={shoppingInfo?.recommendsTitle}
            variant="paragraph"
          />
          <ul>
            {personalizeProducts?.map((item, index) => {
              return (
                <CartRecommendItem
                  modal="recommends"
                  form="CartSidebarView"
                  key={`${item.id}${index}`}
                  item={item}
                  discountPrice={item?.__discountPrice || ''}
                  position={index + 1}
                  mainProduct={item?.mainProduct}
                  currencyCode={data.currency.code}
                  shopCommon={shopCommon}
                  allSku={data?.lineItems?.map((item) => item?.variant?.sku)}
                  getBundleSize={getBundleSize}
                  onRecommendsProductAdd={setRecommendsAddedSkuAdded}
                />
              )
            })}
          </ul>
        </div>
      )}

      {/* 运营配置的推荐商品 */}
      {cartRecommends?.show && (
        <div id="RecommendsProducts">
          {recommendsProducts?.length > 0 && (
            <div className="px-5 pb-8 md:pb-4">
              <Text
                className={s.recommendsTitle}
                html={shoppingInfo?.recommendsTitle}
                variant="paragraph"
              />
              <ul className="">
                {recommendsProducts.map((item, index) => {
                  return (
                    <CartRecommendItem
                      modal="recommends"
                      form="CartSidebarView"
                      key={`recommendsProducts-${item.id}${index}`}
                      item={item}
                      discountPrice={item.__discountPrice || ''}
                      position={index + 1}
                      mainProduct={item.mainProduct}
                      currencyCode={data.currency.code}
                      shopCommon={shopCommon}
                      allSku={data?.lineItems?.map(
                        (item) => item?.variant?.sku
                      )}
                      getBundleSize={getBundleSize}
                      onRecommendsProductAdd={setRecommendsAddedSkuAdded}
                    />
                  )
                })}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default DesktopRecommends
