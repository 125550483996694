import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { Button, Text } from '@components/ui'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { TagIcon } from '@heroicons/react/24/solid'
import Spin from '@components/icons/common/Spin'
import { useState } from 'react'
import cn from 'classnames'

const CodeInput = ({
  s,
  shoppingInfo,
  onSubmit,
  codeError,
  setCodeError,
  codeApplying,
  codeRemoving,
  handleCodeRemove,
  discountCodes,
  codeTips,
}) => {
  const [expand, setExpand] = useState(false) // 输入框是否展开
  const [code, setCode] = useState('')
  const showCodeTips =
    discountCodes.length > 0 &&
    discountCodes.some((item) => item?.applicable === true)

  return (
    <div className="border-y border-gray-200 bg-white px-4 py-4">
      <button
        tag="Auto-10000461"
        className="flex w-full items-center text-left"
        onClick={() => setExpand(!expand)}
      >
        <span className="flex-none text-lg">{shoppingInfo?.enterCode}</span>
        <span className="ml-2 flex-grow text-base text-gray-500">
          {shoppingInfo?.optional}
        </span>
        <ChevronDownIcon
          className={cn('h-5 w-5 flex-none transition-all duration-200', {
            ['rotate-[180deg]']: expand,
          })}
        />
      </button>
      <div
        className={cn(
          'h-0 origin-top scale-y-0 overflow-hidden transition-all duration-200',
          {
            ['!h-auto !scale-y-100']: expand,
          }
        )}
      >
        <p className={cn('mb-3 text-gray-500')}>
          {shoppingInfo?.enterCodeInfo}
        </p>
        <form
          className={'flex'}
          onSubmit={async (e) => {
            e.preventDefault()
            const result = await onSubmit(code.trim())
            if (result === 'success') {
              setCode('')
              setCodeError('')
              setExpand(false)
            }
          }}
        >
          <input
            tag="Auto-10000462"
            className="h-[42px] flex-grow rounded-full border bg-white px-3 text-lg"
            placeholder={shoppingInfo?.placeholder || 'Enter Your Promo Code'}
            type="text"
            value={code}
            onChange={(e) => {
              setCode(e.target.value)
              setCodeError('')
            }}
          />
          <Button
            type="submit"
            tag="Auto-10000463"
            className="ml-3 flex-none"
            loading={codeApplying}
          >
            {shoppingInfo?.apply}
          </Button>
        </form>
        {codeError && (
          <Text
            variant="paragraph"
            html={codeError}
            className="margin-0 py-[10px] text-center text-[13px] leading-tight text-[#ee3749]"
          />
        )}
      </div>

      {discountCodes.length > 0 && (
        <div className="flex w-full flex-wrap pt-3">
          {discountCodes?.map((item, i) => {
            if (!item?.applicable) return ''
            return (
              <div
                className="mb-1 mr-1 flex justify-between "
                key={`discountCodes-${item?.code}-${i}`}
              >
                <div className={cn(s.codeIconBox, s.codeIconBoxBg)}>
                  <TagIcon className="inline-block h-4 w-4 align-text-bottom"></TagIcon>
                  <Text
                    variant="inline"
                    html={item.code}
                    className="align-middle"
                    autoTestTag="Auto-10000464"
                  />
                  <button
                    aria-label="remove code"
                    tag="Auto-10000465"
                    variant="plain"
                    className="ml-2 pt-1"
                    onClick={() => handleCodeRemove(item.code)}
                    disabled={codeRemoving}
                    loading={codeRemoving.toString()}
                  >
                    {codeRemoving ? (
                      <Spin className="h-4 w-4" />
                    ) : (
                      <XMarkIcon className="inline-block h-4 w-4 align-top"></XMarkIcon>
                    )}
                  </button>
                </div>
              </div>
            )
          })}
        </div>
      )}

      {codeTips && showCodeTips && (
        <Text
          variant="paragraph"
          html={codeTips}
          className="pt-[10px] text-left text-[13px] leading-tight text-[#ee3749]"
        />
      )}
    </div>
  )
}

export default CodeInput
