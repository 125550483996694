/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback } from 'react'
import cn from 'classnames'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { TagIcon } from '@heroicons/react/24/solid'
import { useCommerce } from '@commerce/index'
import InstalmentsModal from '@components/product/InstalmentsModal'
import Affirm from '@components/product/Affirm'
import Paypal from '@components/product/Paypal'
import CitiPay from '@components/product/CitiPay'
import Klarna from '@components/product/Klarna'

import { createCheckout } from 'lib/shopify/api/checkout'
import { useCart, useCartAttributesUpdate } from 'lib/shopify/api/cart'
import usePrice, { formatPrice } from '@shopify/product/use-price'
import useCustomer from '@shopify/customer/use-customer'
import {
  useCodeApply,
  useRemoveCode,
  useUpdateItem,
} from 'lib/shopify/api/cart'
import { Button, Text, Picture } from '@components/ui'
import { useUI } from '@components/ui/context'
import { Cross } from '@components/icons/next'
import Icon from '@components/icons'
import SidebarLayout from '@components/common/SidebarLayout'
import { commerceEnabled } from '@lib/utils/tools'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { BRAND } from '@shopify/const'
import { beginCheckout, criteoTrace } from '../trace'
import { ProductIconBar, ShoppingCartGifts } from '@components/product'
import { getGtmGroup } from '@components/helper'
import Decimal from 'decimal.js'
import CartItem from '../CartItem'
import MobileRecommends from '../Recommends/Mobile'
import DesktopRecommends from '../Recommends/Desktop'

import useRecommendsProducts from '../useRecommendsProducts'
import useGradientGiftsCampaign from '../useGradientGiftsCampaign'
import useBreakpointDiscount from '../useBreakpointDiscount'

import s from './CartSidebarView.module.css'
import CodeInput from '../CodeInput'
import LogisticsInput from '../LogisticsInput'
import DiscountDescription from '../DiscountDescription'
import useBundleItem from '../useBundleItem'
import CartBanner from '@products/components/MemberDiscount/CartBanner'
import { useRelativeLink } from '@commerce/product/use-relative-link'

import Cookies from 'js-cookie'
import debounce from 'lodash.debounce'

const CartSidebarView = ({ metafields, pageType }) => {
  const error = null
  const success = null
  const {
    shoppingInfo,
    infos = {},
    cartRecommends,
    quantityBreakDiscount: quantityBreakDiscountData,
    criteoSettings,
    shopCommon,
    thirdparty,
    summerCampaignDiscount,
    gradient_gifts,
    fridayBlackChg2023,
    preRender,
    shopPreRender,
    ankerDayPPSDiscount,
    copywriting,
  } = metafields
  const { solixFanCart } = copywriting || {}
  const { setRelativeLink } = useRelativeLink()
  const { shop } = useCommerce()
  const { data: customer } = useCustomer()
  const { locale, query, asPath } = useRouter()
  // console.log('query', useRouter())
  const { closeSidebar, setSidebarView, displaySidebar: show } = useUI()
  const { cartCookie } = useCommerce()
  const { data, isLoading, isEmpty } = useCart()
  const codeApply = useCodeApply()
  const removeCode = useRemoveCode()
  const cartAttributesUpdate = useCartAttributesUpdate()

  const [codeError, setCodeError] = useState(null)
  const [recommendsAddedSku, setRecommendsAddedSkuAdded] = useState() // 推荐商品中加入购物车
  const [codeApplying, setCodeApplying] = useState(false)
  const [codeRemoving, setCodeRemoving] = useState(false)
  const [loadingTocheckout, setLoading] = useState(false)
  const updateItem = useUpdateItem()

  const hasSolixFanPro = useMemo(() => {
    return data?.lineItems?.find((item) => {
      return item?.product?.tags?.includes(solixFanCart?.tag)
    })
  }, [data?.lineItems, customer])

  const appliedCode = useMemo(() => {
    const apply = data?.discountCodes
      ?.filter((item) => item?.applicable)
      ?.map((item) => item?.code)
    return apply?.length > 0 ? apply : ['']
  }, [data?.discountCodes])

  const [toCheckoutUrl, queryString] = useMemo(() => {
    const params = {
      // discount: appliedCode.join(','),
    }
    const queryString = Object.keys(params)
      .map((key) => {
        return !!params[key]
          ? encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
          : ''
      })
      .join('&')

    return [data?.url ? data.url + '?' + queryString : '', queryString]
  }, [data])

  const goToCheckout = useCallback(
    async (data, appliedCode) => {
      setLoading(true)
      let targetUrl = toCheckoutUrl
      try {
        // 兼容方案：在升级 checkout.liquid 前，无法从cart带多个discount到checkout
        if (data?.lineItems?.length > 0 && appliedCode?.length > 1) {
          const checkout = await createCheckout({
            locale,
            lineItems: data?.lineItems?.map((item) => ({
              variantId: item?.variant?.id,
              quantity: item?.quantity,
              ...(item?.customAttributes && {
                customAttributes: item?.customAttributes,
              }),
            })),
            customAttributes: data?.customAttributes,
            discountCodes: appliedCode,
          })

          targetUrl = checkout?.webUrl + '&' + queryString
          localStorage.setItem('checkoutwebid', checkout?.id)
        }
      } catch (e) {
        console.log(e)
      }
      window.location.assign(targetUrl)
      setLoading(false)
    },
    [toCheckoutUrl, queryString]
  )

  const appliedOrderCode = useMemo(() => {
    const codes = data?.discountAllocations?.reduce((arr, cur) => {
      if (arr.every((item) => item?.code !== cur?.code)) arr.push(cur)
      return [...arr]
    }, [])
    const result = codes?.map((item) => {
      const amountTotal = data?.discountAllocations?.reduce((pre, cur) => {
        if (cur?.code === item?.code) return Number(pre) + Number(cur.amount)
        return Number(pre)
      }, 0)

      return {
        amount: amountTotal,
        amountFormat: formatPrice({
          amount: amountTotal * -1,
          currencyCode: data?.currency?.code,
          locale,
        }),
        code: item.code,
      }
    })
    return result
  }, [data?.discountAllocations])

  const { personalizeProducts, recommendsProducts } = useRecommendsProducts({
    recommendsAddedSku,
    cartRecommends,
    shoppingInfo,
    show,
  })

  const has_appointment_tags = useMemo(() => {
    if (!shoppingInfo?.hes_appointment_method) return []
    if (shoppingInfo?.hes_appointment_method?.skus?.length > 0) {
      return data?.lineItems?.filter((item) =>
        shoppingInfo?.hes_appointment_method?.skus?.includes(item?.variant?.sku)
      )
    }
    return (
      data?.lineItems?.filter((item) =>
        item?.product?.tags?.some((t) =>
          shoppingInfo?.hes_appointment_method?.tags?.includes(t)
        )
      ) || []
    )
  }, [data?.lineItems])

  useEffect(() => {
    if (!shoppingInfo?.hes_appointment_method) return
    const has_appointment_method = data?.customAttributes?.find(
      (attribute) =>
        attribute.key === shoppingInfo?.hes_appointment_method?.attribute_key
    )

    if (!has_appointment_method && has_appointment_tags?.length > 0) {
      cartAttributesUpdate({
        customAttributes: [
          {
            key: shoppingInfo?.hes_appointment_method?.attribute_key,
            value: shoppingInfo?.hes_appointment_method?.values[0]?.value,
          },
        ],
      })
    }
    if (has_appointment_method && has_appointment_tags?.length === 0) {
      cartAttributesUpdate({
        customAttributes: data?.customAttributes?.filter(
          (attribute) =>
            attribute.key !==
            shoppingInfo?.hes_appointment_method?.attribute_key
        ),
      })
    }
  }, [shoppingInfo?.hes_appointment_method, data?.lineItems])

  useEffect(() => {
    if (
      customer &&
      !data?.customAttributes?.find(
        (attribute) =>
          attribute.key === '_login_user' && attribute.value === '1'
      )
    ) {
      const checkoutIdCookie = cartCookie[locale]
      const cartId = Cookies.get(checkoutIdCookie)
      const r = cartAttributesUpdate({
        customAttributes: [{ key: '_login_user', value: '1' }],
      })
    }
  }, [data?.lineItems?.length, customer])

  const applyCode = async (code, only) => {
    setCodeApplying(true)
    setCodeError(null)
    try {
      await codeApply({ discountCode: code, only: !!only })
      setCodeApplying(false)
      return 'success'
    } catch (error) {
      setCodeApplying(false)
      setCodeError(error.message)
    }
  }

  const codeRemove = async (successCbk, code) => {
    // console.log('code remove:')
    try {
      setCodeRemoving(true)
      await removeCode({ discountCode: code })
      successCbk && successCbk()
      setCodeRemoving(false)
    } catch (error) {
      setCodeRemoving(false)
    }
  }

  const { price: totalTaxAmount } = usePrice(
    data?.totalTaxAmount && {
      amount: Number(data?.totalTaxAmount || 0),
      currencyCode: data.currency.code,
    }
  )

  const hasDiscount = useMemo(
    () => data?.discountCodes?.some((code) => code.applicable),
    [data?.discountCodes]
  )

  const {
    involvedLines: gradientGiftsLines,
    reorder,
    nextSummerLevel,
    involvedSubTotal,
  } = useGradientGiftsCampaign({
    campaign: gradient_gifts,
    _giveaway: '_giveaway_gradient_gifts',
  })

  const {
    involvedLines: summerCampaignLines,
    reorder: summerReorder,
    nextSummerLevel: nextLevel,
    involvedSubTotal: nextInvolvedSubTotal,
  } = useGradientGiftsCampaign({
    campaign: summerCampaignDiscount,
    _giveaway: '_giveaway_summerCampaignDiscount',
  })

  const {
    showLearnMore,
    quantityBreakCode,
    setQuantityBreakCodeRemoved,
    isQuantityBreakCode,
    isNormalCode,
  } = useBreakpointDiscount({
    quantityBreakDiscountData,
    disabled: gradientGiftsLines?.length > 0 || summerCampaignLines?.length > 0,
    hasDiscount,
    applyCode,
    codeRemove,
    setCodeError,
    appliedCode: appliedCode[0],
  })

  useEffect(() => {
    if (show) {
      criteoTrace({ data, locale, criteoSettings, customer, thirdparty })
    }
  }, [show, data?.lineItems, locale])

  const handleCodeRemove = (code) =>
    codeRemove(() => {
      // console.log('quantityBreakCode:', quantityBreakCode)
      // 如果去除了所有的普通 code，并且有符合满减条件，默认应用上满减
      const isLast = appliedCode?.length <= 1
      if (isNormalCode(code) && quantityBreakCode && isLast) {
        applyCode(quantityBreakCode)
      }
      if (isQuantityBreakCode(code)) {
        setQuantityBreakCodeRemoved(true)
      }
    }, code)

  const { price: subTotal } = usePrice(
    data && {
      amount: Number(data.lineItemsSubtotalPrice),
      currencyCode: data.currency.code,
    }
  )

  const { price: total } = usePrice(
    data && {
      amount: Number(data.totalPrice),
      currencyCode: data.currency.code,
    }
  )

  const bundleItem = useBundleItem()

  const CUSTOMCHECKOUT = useMemo(
    () => commerceEnabled('customCheckout', locale),
    [locale]
  )

  const handleClose = () => {
    closeSidebar()
    pageGTMEvent({
      event: 'close_side_cart',
      page_group: document.location.href,
    })
  }

  const getBundleSize = (name) => {
    const bundle_size = data?.lineItems?.filter((item) => {
      return item?.customAttributes?.some((obj) => obj.value === name)
    })
    return bundle_size ? bundle_size?.length : 1
  }

  const productMix = useMemo(() => {
    if (!fridayBlackChg2023) return
    // 判断是否有小充商品
    let chgPro
    let ppsPro
    data?.lineItems?.map((item) => {
      if (!chgPro) {
        chgPro = item?.product?.tags?.find((tag) =>
          fridayBlackChg2023?.chg_tags?.includes(tag)
        )
      }
      if (!ppsPro) {
        ppsPro = item?.product?.tags?.find((tag) =>
          fridayBlackChg2023?.pps_tags?.includes(tag)
        )
      }
    })
    if (chgPro && ppsPro) return true
    return false
  }, [data])

  const codeColseTips = useMemo(() => {
    if (!fridayBlackChg2023) return
    // 筛选出小充产品
    const chgItem = data?.lineItems?.filter((item) => {
      const tags = item?.product?.tags || []
      return fridayBlackChg2023?.chg_tags.some((tag) => tags.includes(tag))
    })

    // 小充产品当前总价
    let curTotal = 0
    // 小充产品黑五活动总价
    let dealsTotal = 0

    let dealsSubtotal = 0
    chgItem?.map((item) => {
      dealsSubtotal += Number(item?.subtotalAmount)
      curTotal += Number(item?.totalAmount)
    })
    const chgQuantity = chgItem?.reduce((q, i) => q + i.quantity, 0) || 0
    const discountKeys = Object.keys(fridayBlackChg2023?.discount)
    const lastKey =
      discountKeys.length > 0 ? discountKeys[discountKeys.length - 1] : 0
    const number = Math.min(chgQuantity, lastKey)
    const discountText = fridayBlackChg2023?.discount[number] || 0.25
    const text = fridayBlackChg2023?.code_tips
      .replace('$value', discountText * 100 + '%')
      .replace('$quantity', number)
    // 换算到分 再进行折扣计算
    const sale_price =
      (dealsSubtotal * 100 - dealsSubtotal * 100 * discountText) / 100
    // 总分价向上取整后再保留两位小数
    dealsTotal = Math.ceil(sale_price * 100) / 100
    // console.log(number, discountText, sale_price, dealsTotal, 'dealsTotal')
    if (chgQuantity > 1 && curTotal > dealsTotal) return text
    return false
  }, [data])

  const handleUpdate = useCallback(
    debounce(() => {
      if (
        customer &&
        data?.lineItems?.length > 0 &&
        ankerDayPPSDiscount &&
        ankerDayPPSDiscount?.customAttribute === '_anker_day'
      ) {
        const allSku =
          ankerDayPPSDiscount?.products?.flat()?.map((item) => item?.sku) || []
        const dataLineItems = []
        data?.lineItems?.forEach((i) => {
          if (
            i?.customAttributes?.indexOf('_anker_day') === -1 &&
            allSku?.includes(i?.variant?.sku)
          ) {
            dataLineItems?.push({
              ...i,
              customAttributes: [
                {
                  key: '_anker_day',
                  value: '_anker_day',
                },
              ],
            })
          }
        })
        if (dataLineItems?.length > 0) {
          updateItem(dataLineItems)
        }
      }
    }, 600), // 300ms 的防抖时间，可以根据需要调整
    [customer, data?.lineItems, ankerDayPPSDiscount, updateItem]
  )

  useEffect(() => {
    handleUpdate()
  }, [data?.lineItems?.length, customer])

  if (!shoppingInfo) return null
  return (
    <SidebarLayout
      className={cn(s.root, {
        [s.empty]: error || success || isLoading || isEmpty,
      })}
      handleClose={handleClose}
      headerTitle={
        isEmpty
          ? `<b>${shoppingInfo?.myCart || 'My Cart'}</b> (0)`
          : `<b>${shoppingInfo?.myCart || 'My Cart'}</b> ${
              data?.lineItems?.length
            } ${shoppingInfo?.items || 'items'}`
      }
    >
      {isLoading || isEmpty ? (
        <div className="flex flex-1 flex-col items-center px-4">
          <span className="flex h-16 w-16 items-center justify-center p-12">
            <Icon iconKey="cart" className="absolute h-8 w-8 text-black" />
          </span>
          <h2 className="text-center text-2xl font-bold">
            {shoppingInfo?.emptyCart}
          </h2>
          <a
            href={
              shoppingInfo?.continueLink
                ? setRelativeLink({ link: shoppingInfo.continueLink })
                : `${locale === 'us' ? '' : '/' + locale}/collections/all`
            }
            className="px-10 pt-2 text-center text-anker-color hover:underline"
          >
            {shoppingInfo?.continue}
          </a>
        </div>
      ) : error ? (
        <div className="flex flex-1 flex-col items-center justify-center px-4">
          <span className="flex h-16 w-16 items-center justify-center rounded-full border border-white">
            <Cross width={24} height={24} />
          </span>
          <h2 className="pt-6 text-center text-xl font-light">
            {shoppingInfo?.errorCart}
          </h2>
        </div>
      ) : (
        <div className={s.cartContainer}>
          {/* 原购物车 => PC 左 */}
          <div className={s.left}>
            {/* 免邮 only us */}
            {(shoppingInfo?.main ||
              gradient_gifts?.cartUnReach ||
              fridayBlackChg2023) && (
              <div
                className={cn(`px-4 py-2 `, s.shoppingInfo, {
                  [s.member]: shoppingInfo?.membersText,
                })}
              >
                {(shoppingInfo?.membersText || shoppingInfo?.main) && (
                  <div
                    className={cn({
                      [s.memberWrap]: shoppingInfo?.membersText,
                    })}
                  >
                    {shoppingInfo?.membersIcon && shoppingInfo?.membersText && (
                      <Picture
                        className={'h-[32px] w-[32px]'}
                        source={shoppingInfo?.membersIcon}
                        alt={shoppingInfo?.membersIconAlt || 'icon'}
                      />
                    )}
                    <Text
                      html={shoppingInfo?.membersText || shoppingInfo?.main}
                    />
                  </div>
                )}
                {hasSolixFanPro && <Text html={solixFanCart?.tips}></Text>}
                {nextSummerLevel && (
                  <Text
                    html={gradient_gifts?.cartUnReach
                      ?.replace('$product', nextSummerLevel.description)
                      ?.replace(
                        '$value',
                        formatPrice({
                          amount: new Decimal(nextSummerLevel.breakpoint)
                            .minus(involvedSubTotal)
                            .toNumber(),
                          currencyCode: data?.currency?.code,
                          locale,
                        })
                      )}
                    className="!text-anker-color"
                  />
                )}
                {nextLevel && (
                  <Text
                    html={summerCampaignDiscount?.cartUnReach
                      ?.replace('$product', nextLevel.description)
                      ?.replace(
                        '$value',
                        formatPrice({
                          amount: new Decimal(nextLevel.breakpoint)
                            .minus(nextInvolvedSubTotal)
                            .toNumber(),
                          currencyCode: data?.currency?.code,
                          locale,
                        })
                      )}
                    className="!text-anker-color"
                  />
                )}
                {productMix && fridayBlackChg2023?.cart_tips && (
                  <Text html={fridayBlackChg2023.cart_tips} />
                )}
              </div>
            )}

            <CartBanner metafields={metafields} />
            {/* 主内容部分 */}
            {gradient_gifts?.cartDescription && (
              <div className="">
                <ShoppingCartGifts lang={gradient_gifts} />
              </div>
            )}
            <div className={s.cartItems}>
              {/* 商品 List */}
              <ul className="border-accent-2 bg-white px-4 py-4 sm:py-2">
                {data?.lineItems
                  ?.sort(reorder)
                  ?.sort(summerReorder)
                  ?.map((item, index) => (
                    <CartItem
                      form="CartSidebarView"
                      key={`${item.id}${item.variant.price}${index}`}
                      item={item}
                      bundleItem={bundleItem[item.id]}
                      position={index + 1}
                      currencyCode={data?.currency?.code}
                      shopCommon={shopCommon}
                      allSku={data?.lineItems?.map(
                        (item) => item?.variant?.sku
                      )}
                      // removeItemCbk={handleRemoveItem}
                      quantityBreakDiscountData={quantityBreakDiscountData}
                      quantityBreakDescription={
                        showLearnMore &&
                        gradientGiftsLines?.length === 0 &&
                        summerCampaignLines?.length === 0 && (
                          <>
                            <DiscountDescription
                              data={quantityBreakDiscountData}
                            />
                            {/* 当前有应用上的 code 不且是满减的 code 且已满足满减情况提示用户 */}
                            {isNormalCode(appliedCode) && quantityBreakCode && (
                              <Text
                                html={quantityBreakDiscountData?.unAvailable}
                                className="text-xs text-red md:text-[3vw]"
                                variant="paragraph"
                              />
                            )}
                          </>
                        )
                      }
                    />
                  ))}
              </ul>
              {shoppingInfo?.hes_appointment_method &&
                has_appointment_tags?.length > 0 && (
                  <LogisticsInput
                    loading={isLoading}
                    shoppingInfo={shoppingInfo}
                    initAttributes={data?.customAttributes?.find(
                      (attribute) =>
                        attribute.key ===
                        shoppingInfo?.hes_appointment_method?.attribute_key
                    )}
                    onConfirm={(val) => {
                      console.log('val', val)
                      cartAttributesUpdate({
                        customAttributes: [
                          {
                            key: shoppingInfo?.hes_appointment_method
                              ?.attribute_key,
                            value: val,
                          },
                        ],
                      })
                    }}
                  />
                )}
              {shoppingInfo?.showDiscounts && (
                <CodeInput
                  shoppingInfo={shoppingInfo}
                  codeError={codeError}
                  onSubmit={applyCode}
                  setCodeError={setCodeError}
                  codeApplying={codeApplying}
                  codeRemoving={codeRemoving}
                  handleCodeRemove={handleCodeRemove}
                  discountCodes={data?.discountCodes}
                  // codeTips={codeColseTips && fridayBlackChg2023?.code_tips}
                  codeTips={codeColseTips}
                  s={s}
                />
              )}
              {/* 邮费税率 */}
              <div
                className={cn(
                  'flex flex-col border-b border-gray-200 bg-white px-4 py-2',
                  {
                    ['border-t']: !(
                      !hasDiscount && shoppingInfo?.showDiscounts
                    ),
                  }
                )}
              >
                {shoppingInfo?.shipping && (
                  <div className="flex justify-between py-1">
                    <span>{shoppingInfo?.shipping}</span>
                    <span className="font-bold tracking-wide">
                      {shoppingInfo?.free}
                    </span>
                  </div>
                )}
                {shoppingInfo?.taxes && data?.totalTaxAmount <= 0 && (
                  <div className="flex justify-between py-1">
                    <span>{shoppingInfo?.taxes}</span>
                    <span>{shoppingInfo?.taxesInfo}</span>
                  </div>
                )}
              </div>
              {/* 产品推荐 => 移动端 下方 */}
              <MobileRecommends
                personalizeProducts={personalizeProducts}
                recommendsProducts={recommendsProducts}
                cartRecommends={cartRecommends}
                shoppingInfo={shoppingInfo}
                getBundleSize={getBundleSize}
                setRecommendsAddedSkuAdded={setRecommendsAddedSkuAdded}
                data={data}
                shopCommon={shopCommon}
              />

              {/* 卖点部分 */}
              <div className={cn(s.reasons, BRAND)}>
                <Text
                  className={cn(s.title, BRAND)}
                  html={infos?.store_iconBar?.title}
                  variant="paragraph"
                />
                <ProductIconBar
                  iconBars={infos?.store_iconBar?.data}
                  direction={infos?.store_iconBar?.direction}
                  s={
                    BRAND === 'ankermake'
                      ? {
                          iconBars:
                            'relative flex justify-between items-stretch content-between py-3 flex-col',
                        }
                      : {
                          iconBars:
                            'grid grid-cols-2 gap-x-9 gap-y-2 bg-[#F3F4F6]',
                          iconBar: 'flex items-start',
                          barText: 'text-[13px] leading-[18px]',
                          icon: 'w-6 h-6 mr-3 flex-shrink-0',
                        }
                  }
                  interaction={BRAND != 'ankermake' && false}
                />
              </div>
            </div>
            {/* 底部悬浮条 */}
            <div className={s.stickyBar}>
              {(appliedOrderCode?.length > 0 || data?.totalTaxAmount > 0) && (
                <ul className="border-b border-accent-2 py-2">
                  {/* subtotal */}
                  <li className="flex justify-between py-1">
                    <span>{shoppingInfo?.subtotal}</span>
                    <span>{subTotal}</span>
                  </li>
                  {data?.totalTaxAmount > 0 && (
                    <li className="flex justify-between py-1">
                      <span>
                        {shoppingInfo?.estimatedTaxes || 'Estimated taxes'}
                      </span>
                      <span>{totalTaxAmount}</span>
                    </li>
                  )}

                  {/* discount */}
                  {hasDiscount &&
                    appliedOrderCode &&
                    appliedOrderCode?.length > 0 && (
                      <li className={s.discountTxt}>
                        <div className="flex w-full">
                          <span className="leading-7">
                            {shoppingInfo?.discount}
                          </span>
                          {/* 折扣标签 */}
                          <div className="grow">
                            {appliedOrderCode?.map((item, i) => {
                              return (
                                <div
                                  className="flex justify-between"
                                  key={`order-${item?.code}-${i}`}
                                >
                                  <div className={s.codeIconBox}>
                                    <TagIcon className="mr-1 inline-block h-4 w-4 align-text-bottom"></TagIcon>
                                    <Text
                                      variant="inline"
                                      html={item.code}
                                      className="align-middle"
                                    />
                                  </div>
                                  <span className="leading-7">
                                    {item.amountFormat}
                                  </span>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </li>
                    )}
                </ul>
              )}

              {/* total */}
              <div className="mb-2 flex justify-between pt-4 font-bold">
                <span>{shoppingInfo?.total}</span>
                <span tag="Auto-10000413" className="text-xl font-semibold">
                  {
                    // total 包含预估税额,预估税额现在逻辑没理清楚，暂时使用subTotal展示
                    // total
                    total
                  }
                </span>
              </div>
              {/* checkout 按钮 */}

              <div>
                <Button
                  tag="Auto-10000414"
                  className="min-h-[42px]"
                  width="100%"
                  id="netlify_dtctest_checkout"
                  loading={loadingTocheckout}
                  onClick={async () => {
                    goToCheckout(data, appliedCode)
                    beginCheckout({
                      data,
                      group: getGtmGroup({ query, asPath }),
                    })
                  }}
                >
                  {shoppingInfo?.checkoutBtn}
                </Button>
              </div>

              {Number(data?.totalPrice) > 100 && (
                <InstalmentsModal pageType={pageType} metafields={metafields}>
                  {thirdparty?.paypal?.src && (
                    <div className="border-b border-[#D9D9D9] px-4 py-4">
                      <Paypal price={data?.totalPrice} />
                    </div>
                  )}
                  {(preRender?.hideKlarna !== undefined
                    ? !preRender?.hideKlarna
                    : !shopPreRender?.hideKlarna) && (
                    <div className="border-b border-[#D9D9D9] px-4 py-4">
                      <Klarna
                        price={data?.totalPrice}
                        settings={metafields?.klarnaSettings}
                      />
                    </div>
                  )}
                  {(preRender?.hideAffirm !== undefined
                    ? !preRender?.hideAffirm
                    : !shopPreRender?.hideAffirm) && (
                    <div className="px-4 py-4">
                      <Affirm price={data?.totalPrice} />
                    </div>
                  )}
                </InstalmentsModal>
              )}
            </div>
          </div>
          {/* 产品推荐 => PC端 右侧 */}
          <DesktopRecommends
            personalizeProducts={personalizeProducts}
            recommendsProducts={recommendsProducts}
            cartRecommends={cartRecommends}
            shoppingInfo={shoppingInfo}
            getBundleSize={getBundleSize}
            setRecommendsAddedSkuAdded={setRecommendsAddedSkuAdded}
            data={data}
            shopCommon={shopCommon}
            show={show}
          />
        </div>
      )}
    </SidebarLayout>
  )
}

export default CartSidebarView
